
import React from "react";
import {Layout, FloatButton, Modal} from "antd";
import {BugOutlined, InfoCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

export default function Footer(props: any) {
    return (
        <Layout.Footer style={{padding: 24, color:'gray'}}>
            © 2021 - {dayjs().year()} ITP-Consult Gesellschaft für IT-Beratung mbH
        </Layout.Footer>
    );
}
import * as $ from 'jquery'
import {
    Button,
    Card,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    notification,
    Radio,
    Select,
    Space,
    Steps,
    Table,
    theme,
    Tooltip
} from "antd";
import React, {useEffect, useState} from "react";
import {APIHost} from "../config";
import CustomSelect from "../components/select";
import {v4 as uuidv4} from 'uuid';
import {ExportOutlined} from "@ant-design/icons";
import dayjs from "dayjs";


const {RangePicker} = DatePicker;
const {useToken} = theme;

interface Sensor {
    name: string,
    aggregation: string,
    key: string,
}

export default function UrlConfigView() {
    const {token} = useToken();

    const [step, setStep] = useState(0)
    const [form] = Form.useForm()

    const [rangetype, setRangetype] = useState("abs")
    const [format, setFormat] = useState<any>({
        format: "DD.MM.YY HH:00",
        showTime: true,
        picker: "date",
    })

    const [selectedSensors, setSelectedSensors] = useState<Sensor[]>([])
    const [availableSensors, setAvailableSensors] = useState<String[]>([])

    const [addSensorName, setAddSensorName] = useState<string>('')
    const [url, setUrl] = useState<string>('')

    // load available sensors
    useEffect(() => {
        $.ajax(
            {
                method: 'get',
                url: new URL("sensors", APIHost).toString(),
            }
        ).done((data: any) => {
            let sensors = data['sensors']
            setAvailableSensors(sensors);
        }).fail((data: any) => {
            let error
            if (data!.responseJSON && data.responseJSON.error) {
                error = data!.responseJSON!.error
            } else {
                error = "Server nicht erreichbar"
            }
            setTimeout(notification.open, 0, {
                message: "Fehler",
                description: error,
                type: 'error',
                duration: 0,
            })
        })
    }, [])

    // change range picker format based on selected frequency
    const updateFormat = () => {
        let freq = form.getFieldValue("freq")
        let format = {
            "C1": "DD.MM.YY HH:mm",
            "C2": "DD.MM.YY HH:mm",
            "C3": "DD.MM.YY HH:mm",
            "C4": "DD.MM.YY HH:00",
            "C5": "DD.MM.YY",
            "C6": "MMMM YY",
        }[freq as string]
        if (!format) {
            format = "DD.MM.YY HH:mm:ss"
        }
        setFormat({
            format: format,
            showTime: format.includes("HH:"),
            picker: format.includes("DD.MM.YY") ? "date" : "month",
        })
        form.setFieldsValue({range: [null, null]})
    }

    const buildUrl = () => {
        let values = form.getFieldsValue()
        let new_url = new URL("data.csv", APIHost).toString()

        if (values!.rangetype === 'abs') {
            let range = values!.range as [Date, Date]
            console.debug(range)
            if (range[0] !== null && range[1] !== null) {
                new_url += "?start=" + range[0].toISOString() + "&end=" + range[1].toISOString()
            }
        }
        if (values!.rangetype === 'rel') {
            let offset = values!.rangeoffsetnumber as number
            let unit = values!.rangeoffsetunit as string
            new_url += "?range=" + offset + unit
        }
        new_url += "&freq=" + values!.freq
        new_url += "&sensors=" + selectedSensors.map((sensor) => sensor.name + ":" + sensor.aggregation).join(",")

        console.debug(new_url)
        setUrl(new_url)
    }

    useEffect(() => {
        buildUrl()
    }, [selectedSensors])

    const newQuery = () => {
        /* start new query, reset all configs */
        let values = form.getFieldsValue()
        values = {
            ...values,
            freq: "C4",
            range: [null, null],
            rangeoffsetnumber: 10,
            rangeoffsetunit: "d",
        }
        form.setFieldsValue(values)
        setSelectedSensors([])
        setStep(1)
    }

    const loadQuery = (url: string) => {
        /* load existing query, set configs from url */

        // parse url
        let params = new URL(url).searchParams
        let freq = params.get("freq")
        let range = params.get("range")
        let start = params.get("start")
        let end = params.get("end")
        let sensors = params.get("sensors")!.split(",").map((sensor) => {
            let [name, aggregation] = sensor.split(":")
            return {name: name, aggregation: aggregation, key: uuidv4()} as Sensor
        })

        // extract form values
        let values = {
            freq: freq,
            range: [start ? dayjs(start) : null, end ? dayjs(end) : null],
            rangetype: range ?  "rel": "abs",
            rangeoffsetnumber: range ? parseInt(range!.slice(0, -1)) : 10,
            rangeoffsetunit: range ? range!.slice(-1): "d",
        }

        // set form values
        form.setFieldValue("freq", freq)
        updateFormat()  // update format based on freq, before setting range
        form.setFieldsValue(values)
        setSelectedSensors(sensors)

        // move to next step
        setStep(1)
    }

    const page1 = (<div>
        <h1>Willkommen im Konfigurator <br/>des Excel-Adapters</h1>
        <p>Neue Abfrage erstellen</p>
        <Button
            type='primary'
            onClick={() => newQuery()}
            children={"Neue Abfrage"}
        />

        <Divider children={<p>oder</p>}/>
        <p>Bestehende Abfrage anpassen</p>
        <Form
            onFinish={(values) => loadQuery(values.url)}
        >
            <Space>
                <Form.Item
                    required
                    name={"url"}
                    rules={[{required: true, message: 'Bitte geben Sie eine URL ein.'}]}
                >
                    <Input placeholder={"URL"} id={"input-url"}/>
                </Form.Item>
                <Form.Item>
                    <Button
                        type='primary'
                        htmlType={"submit"}
                        children={"Abfrage laden"}
                    />
                </Form.Item>
            </Space>
        </Form>

    </div>)

    const page2 = (<Space
            direction={'vertical'}
            size={24}
        >
            <Card
                style={{width: 920}}
            >
                <Form
                    form={form}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    onChange={() => buildUrl()}
                >
                    <Form.Item label={"Frequenz"} name={"freq"}>
                        <CustomSelect
                            placeholder={"Frequenz wählen"}
                            onChange={() => {
                                updateFormat();
                                buildUrl();
                            }
                            }
                        >
                            <Select.Option value={"C1"}>1 Minute</Select.Option>
                            <Select.Option value={"C2"}>5 Minuten</Select.Option>
                            <Select.Option value={"C3"}>15 Minuten</Select.Option>
                            <Select.Option value={"C4"}>1 Stunde</Select.Option>
                            <Select.Option value={"C5"}>1 Tag</Select.Option>
                            <Select.Option value={"C6"}>1 Monat</Select.Option>
                        </CustomSelect>
                    </Form.Item>

                    <Form.Item label={"Zeitraum"} style={{width: '100%'}}>
                        <Space direction={'vertical'}>
                            <Form.Item name={"rangetype"} noStyle>
                                <Radio.Group
                                    style={{width: '100%'}}
                                    optionType={"button"}
                                    buttonStyle={"solid"}
                                    defaultValue={"abs"}
                                    onChange={(e) => {
                                        setRangetype(e.target.value);
                                        buildUrl();
                                    }
                                    }
                                    options={[
                                        {label: 'Absolut', value: 'abs'},
                                        {label: 'Relativ', value: 'rel'},
                                    ]}
                                />
                            </Form.Item>
                            <div hidden={rangetype !== 'rel'}>
                                <Space direction={'vertical'}>
                            <span style={{color: 'gray'}}>
                                Mit jedem aktualisieren der Daten werden<br/>
                                die neuesten Daten geladen
                            </span>
                                    <Space.Compact>
                                        <Form.Item name={"rangeoffsetnumber"} noStyle>
                                            <InputNumber min={1} name={'rangeoffsetnumber'}
                                                         onChange={() => buildUrl()}/>
                                        </Form.Item>
                                        <Form.Item name={"rangeoffsetunit"} noStyle>
                                            <Select
                                                onChange={() => buildUrl()}
                                                options={[{value: "m", label: "Minuten"},
                                                    {value: "h", label: "Stunden"},
                                                    {value: "d", label: "Tage"}]}
                                                style={{width: '150px!important'}}
                                            />
                                        </Form.Item>
                                    </Space.Compact>
                                </Space>
                            </div>
                            <div hidden={rangetype !== 'abs'}>
                                <Space direction={'vertical'}>
                                    <span style={{color: 'gray'}}>Ein fester Zeitraum</span>
                                    <Form.Item name={"range"} style={{marginBottom: 0}}>
                                        <RangePicker
                                            onChange={() => buildUrl()}
                                            name={'range'}
                                            picker={format.picker}
                                            showTime={format.showTime}
                                            format={format.format}
                                        />
                                    </Form.Item>
                                </Space>
                            </div>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
            <Card
                title="Sensoren"
                style={{width: 920}}
                styles={{
                    header: {textAlign: 'left'},
                    body: {padding: 0},
                }}
                extra={<Space size={12}>
                    <CustomSelect
                        style={{width: 500}}
                        searchable
                        value={addSensorName}
                        onChange={(value: any) => setAddSensorName(value)}
                        children={availableSensors.map((sensor) => (
                            <Select.Option value={sensor}>{sensor}</Select.Option>
                        ))}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            if (addSensorName === '') {
                                return
                            }
                            let uuid = uuidv4()
                            setSelectedSensors([...selectedSensors, {
                                name: addSensorName,
                                aggregation: "mean",
                                key: uuid,
                            } as Sensor])
                            setAddSensorName('')
                        }}
                    >Hinzufügen</Button>
                </Space>}
            >
                <Table
                    dataSource={selectedSensors}
                    pagination={false}
                    rowKey={"key"}
                    size={"small"}
                >
                    <Table.Column
                        render={(v, r, i) => (
                            <Space>
                                <Button
                                    children={"↑"}
                                    onClick={() => {
                                        if (i === 0) {
                                            return
                                        }
                                        let b = selectedSensors
                                        let temp = b[i]
                                        b[i] = b[i - 1]
                                        b[i - 1] = temp
                                        setSelectedSensors([...b])
                                    }
                                    }
                                />
                                <Button
                                    children={"↓"}
                                    onClick={() => {
                                        if (i === selectedSensors.length - 1) {
                                            return
                                        }
                                        let b = selectedSensors
                                        let temp = b[i]
                                        b[i] = b[i + 1]
                                        b[i + 1] = temp
                                        setSelectedSensors([...b])
                                    }
                                    }
                                />
                            </Space>
                        )}  // render up down buttons
                    />
                    <Table.Column title={"Name"} dataIndex={"name"}/>
                    <Table.Column
                        title={"Aggregation"}
                        dataIndex={"aggregation"}
                        render={(v, r, i) => {
                            return (<Select
                                value={v}
                                style={{width: 200}}
                                onChange={(value) => {
                                    let b = selectedSensors
                                    b[i].aggregation = value
                                    setSelectedSensors([...b])
                                }
                                }
                                children={[
                                    <Select.Option value={"mean"}>Mittelwert</Select.Option>,
                                    <Select.Option value={"min"}>Minimum</Select.Option>,
                                    <Select.Option value={"max"}>Maximum</Select.Option>,
                                ]}
                            />)
                        }}
                    />
                    <Table.Column
                        render={(v, r, i) => (
                            <Button
                                onClick={() => {
                                    let b = selectedSensors
                                    b.splice(i, 1)
                                    setSelectedSensors([...b])
                                }}
                                children={"Entfernen"}
                            />
                        )}
                    />
                </Table>
            </Card>
            <Button
                type='primary'
                onClick={() => {
                    buildUrl();
                    let values = form.getFieldsValue()

                    if (values!.rangetype === 'abs' && (values!.range[0] === null || values!.range[1] === null)) {
                        notification.open({
                            message: 'Fehler',
                            description: 'Bitte wählen Sie einen Zeitraum aus.',
                            type: 'error',
                            duration: 10,
                        })
                    } else if (selectedSensors.length === 0) {
                        notification.open({
                            message: 'Fehler',
                            description: 'Bitte wählen Sie mindestens einen Sensor aus.',
                            type: 'error',
                            duration: 10,
                        })
                    } else {
                        setStep(2);
                    }
                }
                }
                children={"Weiter"}
            />
        </Space>
    )

    const page3 = (
        <div>
            <p>Kopieren Sie den folgenden Link und nutzen Sie ihn in Excel für einen Datenimport via "CSV Datei aus dem
                Web"</p>
            <Space.Compact>
                <Input
                    value={url}
                    style={{width: 880}}
                    readOnly
                />
                <Tooltip overlay={"Kopieren"}>
                    <Button
                        type='primary'
                        onClick={() => {
                            navigator.clipboard.writeText(url)
                            message.success('Link in Zwischenablage kopiert')

                        }}
                        children={<ExportOutlined/>}
                    />
                </Tooltip>
            </Space.Compact>

        </div>
    )

    const pages = [
        page1,
        page2,
        page3,
    ]

    return (
        <Card
            style={{
                margin: 0,
                marginLeft: 12,
                marginRight: 12,
                padding: 24,
                paddingTop: 0,
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
                borderColor: 'rgba(0, 0, 0, 0.1)',
            }}
            styles={{
                body: {
                    height: 'calc(100vh - 60px - 72px - 48px - 64px)',
                    overflow: 'auto',
                },
            }}
            title='Excel-Adaper Konfigurator'
        >
            <Steps
                current={step}
                items={[
                    {
                        title: 'Start',
                    },
                    {
                        title: 'Konfiguration',
                    },
                    {
                        title: 'Export',
                    },
                ]}
                onChange={(current) => setStep(current)}
            />
            <center style={{paddingTop: 48}}>
                {pages[step]}
            </center>
        </Card>
    )
}